<template>
<span
    class="btn btn-icon"
    style="cursor: default !important;"
    :class="[ color, `btn-${size}` ]"
>
    <span
        class="course-icon"
        :style="[{color: hexColor}, styleCourseIcon]"
    >
        {{ shortTitle }}
    </span>
</span>
</template>

<script lang="ts">

import moment from 'moment';
import courseMixins from '../store/mixins/courseMixins';

export default {
    name: 'CourseIcon',
    mixins: [courseMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
        size: { // xs sm md lg xl
            type: String,
            required: false,
            default: 'md',
        },
        styleCourseIcon: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            today: null,
        };
    },
    computed: {
        color() {
            return this.$_courseMixins_getColorForCourse(this.course);
        },
        hexColor() {
            return this.$_courseMixins_getHexColorForCourse(this.course);
        },
        shortTitle() {
            const { nextMeeting, courseSectionIcon, courseSectionAbbreviation } = this.course;
            if (courseSectionIcon) return courseSectionIcon;
            if (courseSectionAbbreviation) return courseSectionAbbreviation;
            if (nextMeeting && nextMeeting.period) {
                return `${nextMeeting.period}`;
            }

            return '-';
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            this.today = moment().format('dddd');
        },
    },
};
</script>
<style>
/* span.course-icon {
    color: #212529;
} */
span.btn-md span.course-icon {
    font-size: 1.3rem;
}

span.btn-xl span.course-icon {
    font-size: 2rem;
}
</style>
<style scoped src='../css/colors.scss' lang="scss" />
