var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "btn btn-icon",
      class: [_vm.color, `btn-${_vm.size}`],
      staticStyle: { cursor: "default !important" },
    },
    [
      _c(
        "span",
        {
          staticClass: "course-icon",
          style: [{ color: _vm.hexColor }, _vm.styleCourseIcon],
        },
        [_vm._v(" " + _vm._s(_vm.shortTitle) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }